(() => {
  const COUNTRY_OTHERS_OPTION_VALUE = '996';

  document.addEventListener('input', function (event) {

    const isCountrySelector = event.target.nodeName === 'SELECT' && event.target.classList.contains('js-country-selector');

    if (!isCountrySelector) return;

    const countrySelector = event.target;
    const syncWith = countrySelector.dataset.valueSyncWithSelector;

    if (!syncWith) return;

    const selectedCountryCode = countrySelector.value;

    document.querySelectorAll(syncWith)?.forEach((target) => {
      const newValue = target.querySelector(`[data-country="${selectedCountryCode}"]`)?.value;
      newValue !== COUNTRY_OTHERS_OPTION_VALUE && (target.value = newValue);
    });
  });
})()
