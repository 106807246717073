export function init(selector, link, children) {
  let $mobile_locale_menu = $(selector);
  let $select = $('.locale-form').first().find('select');

  if ($select.length) {
    let $selected_option = $select.find('option:selected');
    let option_text = $selected_option.text();
    let $children_cnt = $mobile_locale_menu.find(children);

    $mobile_locale_menu.find(link).text(option_text);

    $select.find('option').each(function (index, el) {
      let $el = $(el);
      let li_template_html = "<li><a href='#'></a></li>";
      let $li = $(li_template_html);

      $li.addClass('locale-option').find('a').attr('data-value', $el.val())
        .text($el.text());
      $li.appendTo($children_cnt);
    });

    $(document).on('click', '.locale-option a', function (event) {
      event.preventDefault();
      let $this = $(this);

      $select.val($this.data('value')).change();
    });
  } else {
    $mobile_locale_menu.remove();
  }
}

if ($('.mobile-menu .language-selector').length) {
  init('.mobile-menu .language-selector', '.parent span', '.is-children');
}
